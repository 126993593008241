import { FunctionComponent, InputHTMLAttributes, useState } from 'react';
import { H4, Text } from 'components/Typography/Typography';
import styled, { css } from 'styled-components';
import { usePlacesWidget } from 'react-google-autocomplete';
import { Spacer } from '../Layout/Spacer';
import { ActionButton } from '../Buttons/ActionButton';

export interface IGoogleAutocomplete
  extends InputHTMLAttributes<HTMLInputElement> {
  value: string;
  error?: string;
  color?: string;
  messageTitle?: string;
  messageText?: string;
  messageButton?: string;
  onChange: any;
}

export const GoogleAutocomplete: FunctionComponent<IGoogleAutocomplete> = ({
  value,
  error,
  color,
  messageTitle,
  messageText,
  messageButton,
  onChange,
  ...rest
}) => {
  const [isMessageShown, showMessage] = useState(false);
  const { ref } = usePlacesWidget({
    apiKey: 'AIzaSyDo5ekZ_r-TDgBwFI007JHp5RH7jdBUW10',
    onPlaceSelected: (place) => {
      onChange(place.address_components[0].long_name);
    },
    options: {
      types: ['(cities)'],
    },
  });

  return (
    <>
      <InputWrapper>
        <InputStyled
          {...rest}
          color={color}
          value={value}
          withMessage={!!messageTitle}
          ref={ref}
          onChange={(event) => onChange(event.target.value)}
          onFocus={() =>
            messageTitle && value.length === 0 && showMessage(true)
          }
        />
        {messageTitle && (
          <InfoButton onClick={() => showMessage(true)}>!</InfoButton>
        )}
        {messageTitle && isMessageShown && (
          <MessageWrapper>
            <H4>{messageTitle}</H4>
            <Text>{messageText}</Text>
            <ButtonsWrapper>
              <ActionButton onClick={() => showMessage(false)}>
                {messageButton}
              </ActionButton>
            </ButtonsWrapper>
          </MessageWrapper>
        )}
      </InputWrapper>
      {error && (
        <>
          <Spacer height={10} />
          <Text color="red">{error}</Text>
        </>
      )}
    </>
  );
};

const InputStyled = styled.input<{ color?: string; withMessage?: boolean }>`
  ${({ theme: { Colors, Consts }, color, withMessage }) => css`
    font-size: 22px;
    font-weight: 200;
    padding: ${withMessage ? '0 40px 0 10px' : '0 10px'};
    height: 48px;
    border: 1px solid ${Colors.inputBorder};
    width: 100%;
    box-shadow: none;
    -webkit-appearance: none;
    background-color: transparent;
    font-family: ${Consts.defaultFontFamily};
    position: relative;
    color: ${color || Colors.inputBorder};

    @media (max-width: ${Consts.tabletBreakPoint}) {
      font-size: 15px;
    }

    &::placeholder {
      color: ${Colors.inputPlaceholderColor};
      font-weight: 200;
      opacity: 0.7;
    }
  `}
`;

const InputWrapper = styled.div`
  ${({ theme: { Consts } }) => css`
    margin-bottom: 17px;
    position: relative;
    width: 100%;

    @media (max-width: ${Consts.tabletBreakPoint}) {
      margin-bottom: 13px;
    }
  `}
`;

const InfoButton = styled.button`
  ${({ theme: { Colors } }) => css`
    border: 1px solid ${Colors.inputBorder};
    position: absolute;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 10px;
    background-color: transparent;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);
    font-size: 14px;
  `}
`;

const MessageWrapper = styled.div`
  ${({ theme: { Consts, Colors } }) => css`
    position: absolute;
    top: 0;
    left: calc(100% + 10px);
    background-color: ${Colors.cookiesWrapperBackgroundColor};
    width: 100%;
    padding: 18px;
    transition: all 0.2s;
    z-index: 1;

    @media (max-width: ${Consts.smallTabletBreakPoint}) {
      left: 0;
      top: 45px;
    }

    > h4 {
      font-size: 12px;
      line-height: 18px;
      color: ${Colors.cookiesWrapperColor};
    }

    > p {
      font-size: 12px;
      line-height: 18px;
      color: ${Colors.cookiesWrapperColor};
    }
  `}
`;

const ButtonsWrapper = styled.div`
  ${({ theme: { Colors } }) => css`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    margin-top: 30px;

    > button {
      height: 50px;
      font-size: 12px;
      min-width: 0;
      background-color: ${Colors.cookiesWrapperColor};
      color: ${Colors.cookiesWrapperBackgroundColor};
      margin-right: 18px;
      margin-bottom: 0;
    }
  `}
`;
