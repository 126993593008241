import React, { FunctionComponent, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import styled, { css } from 'styled-components';
import { TextSmall } from 'components/Typography/Typography';
import { Socials } from 'components/Socials/Socials';
import { useMediaQuery } from 'react-responsive';
import { Consts as StyleConsts } from 'styles/consts';
import { FlexWrapper, FlexBox } from 'components/Layout/Flex';
import { Image } from 'components/Layout/Image';
import { Spacer } from 'components/Layout/Spacer';
import { scrollTop } from 'utils';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Link from 'next/link';
import { Controller, useForm } from 'react-hook-form';
import { EMAIL_REGEX } from '../../consts';
import { FormInputWrapper } from '../Registration/FormInputWrapper';
import { ActionButton } from '../Buttons/ActionButton';

interface IFooter {
  onTopClick?: () => void;
  fullHeight?: boolean;
}

type FormInputs = {
  email: string;
};

export const Footer: FunctionComponent<IFooter> = ({
  onTopClick,
  fullHeight,
}) => {
  const router = useRouter();
  const [image, setBottomImage] = useState('public/images/homeFooter.webp');
  const mobile = useMediaQuery({
    query: `(max-width: ${StyleConsts.mobileBreakPoint})`,
  });
  const currentYear = new Date().getFullYear();
  const [isRegisteringError, setRegisteringError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [done, setDone] = useState(false);

  const { handleSubmit, control } = useForm<FormInputs>({
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = async (data: FormInputs) => {
    setLoading(true);
    await fetch(`/api/newsletter`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: data.email,
      }),
    })
      .then((response) => {
        setLoading(false);

        if (response.status < 400) {
          setDone(true);
        }

        if (response.status > 400 && response.status < 600) {
          setRegisteringError(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    switch (router.pathname) {
      case '/':
        setBottomImage(
          mobile ? '/images/homeFooterMobile.webp' : '/images/homeFooter.webp',
        );
        break;
      case '/about-us':
        setBottomImage(
          mobile ? '/images/infoFooterMobile.webp' : '/images/infoFooter.webp',
        );
        break;
      case '/partner':
        setBottomImage(
          mobile ? '/images/infoFooterMobile.webp' : '/images/infoFooter.webp',
        );
        break;
      case '/partner/internships':
        setBottomImage(
          mobile ? '/images/infoFooterMobile.webp' : '/images/infoFooter.webp',
        );
        break;
      case '/partner/masterclasses':
        setBottomImage(
          mobile ? '/images/infoFooterMobile.webp' : '/images/infoFooter.webp',
        );
        break;
      case '/partner/sponsoring':
        setBottomImage(
          mobile ? '/images/infoFooterMobile.webp' : '/images/infoFooter.webp',
        );
        break;
      case '/competition':
        setBottomImage(
          mobile
            ? '/images/prizeFooterMobile.webp'
            : '/images/prizeFooter.webp',
        );
        break;
      case '/earth':
        setBottomImage(
          mobile
            ? '/images/earthFooterMobile.webp'
            : '/images/earthFooter.webp',
        );
        break;
      case '/terms-and-conditions':
        setBottomImage(
          mobile
            ? '/images/termsAndConditionsFooterMobile.webp'
            : '/images/termsAndConditionsFooter.webp',
        );
        break;
      case '/cookie-policy':
        setBottomImage(
          mobile
            ? '/images/cookiePolicyFooterMobile.webp'
            : '/images/cookiePolicyFooter.webp',
        );
        break;
      case '/privacy-policy':
        setBottomImage(
          mobile
            ? '/images/privacyPolicyFooterMobile.webp'
            : '/images/privacyPolicyFooter.webp',
        );
        break;
      case '/copyright-policy':
        setBottomImage(
          mobile
            ? '/images/copyrightPolicyFooterMobile.webp'
            : '/images/copyrightPolicyFooter.webp',
        );
        break;
      case '/faq':
        setBottomImage(
          mobile ? '/images/woodMobile.webp' : '/images/wood.webp',
        );
        break;
      case '/ambassadors':
        setBottomImage(mobile ? '' : '');
        break;
      default:
        setBottomImage(
          mobile ? '/images/homeFooterMobile.webp' : '/images/homeFooter.webp',
        );
    }
  }, [router, setBottomImage, mobile]);

  const onScrollClick = () => {
    if (onTopClick) {
      onTopClick();
    } else {
      scrollTop();
    }
  };

  return (
    <FooterWrapper fullHeight={fullHeight}>
      <FlexWrapperStyled>
        <FlexBox justify="flex-end">
          <TopWrapper>
            <Link href="/registration">
              <Button>Register</Button>
            </Link>
            <Socials background="#F4F4F4" color="#000000" />
          </TopWrapper>

          <SectionsWrapper>
            <Breaker />
            <Link href="/the-earth-prize-2024">
              <ALink>
                <H3>WINNERS & FINALISTS</H3>
                <MiniButton>
                  <FontAwesomeIcon icon={faArrowRight as IconProp} />
                </MiniButton>
              </ALink>
            </Link>
            <Breaker />
            <Link href="/learning-content">
              <ALink>
                <H3>Learning Content</H3>
                <MiniButton>
                  <FontAwesomeIcon icon={faArrowRight as IconProp} />
                </MiniButton>
              </ALink>
            </Link>
            <Breaker />
            <Link href="/competition">
              <ALink>
                <H3>The Competition</H3>
                <MiniButton>
                  <FontAwesomeIcon icon={faArrowRight as IconProp} />
                </MiniButton>
              </ALink>
            </Link>
            <Breaker />
            <Link href="/about-us">
              <ALink>
                <H3>About us</H3>
                <MiniButton>
                  <FontAwesomeIcon icon={faArrowRight as IconProp} />
                </MiniButton>
              </ALink>
            </Link>
            <Breaker />
          </SectionsWrapper>
          <LegalWrapper>
            <div>
              <Link href="https://drive.google.com/drive/folders/1y7AMJOUEiAfRInPJoFnGsI2kA0pKKCPU?usp=sharing">
                <TextSmall>Media Kit</TextSmall>
              </Link>
              <Link href="/terms-and-conditions">
                <TextSmall>Terms & Conditions</TextSmall>
              </Link>
              <Link href="/cookie-policy">
                <TextSmall>Cookie Policy</TextSmall>
              </Link>
              <Link href="/privacy-policy">
                <TextSmall>Privacy Policy</TextSmall>
              </Link>
              <Link href="/copyright-policy">
                <TextSmall>Copyright Policy</TextSmall>
              </Link>
            </div>
            <div>
              <p>
                Want to stay updated and help us shape a sustainable future?
              </p>
              {done ? (
                <p style={{ color: '#00B7BD' }}>Thanks for joining us!</p>
              ) : (
                <>
                  <Controller
                    control={control}
                    name="email"
                    rules={{
                      required: {
                        value: true,
                        message: 'Email is required',
                      },
                      pattern: {
                        value: EMAIL_REGEX,
                        message: 'Invalid email address',
                      },
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormInputWrapper
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        placeholder="Please provide your email"
                        color={`${error?.message ? 'red' : 'white'}`}
                        error={error?.message}
                      />
                    )}
                  />
                  <ActionButton
                    background="#0AB7BC"
                    color="#000000"
                    isSmall
                    onClick={handleSubmit(onSubmit)}
                  >
                    {isLoading ? 'Loading...' : 'Join us'}
                  </ActionButton>
                </>
              )}
            </div>
          </LegalWrapper>
          <BottomWrapper>
            <TextSmall onClick={() => {}}>
              ©TheEarthPrize {currentYear}
            </TextSmall>
            <Link href="/about-us#contact-us">
              <TextSmall>Contact us</TextSmall>
            </Link>
            <Link href="https://gofund.me/cdaede4f">
              <TextSmall>Donate</TextSmall>
            </Link>
            <Link href="/volunteer">
              <TextSmall>Get Involved</TextSmall>
            </Link>
            <Link href="/partner">
              <TextSmall>Partner with us</TextSmall>
            </Link>
          </BottomWrapper>
        </FlexBox>
        <FlexBox withoutPadding justify="flex-end">
          <ImageStyled image={image}>
            {router.pathname === '/' && (
              <>
                <Quote>
                  “The Earth Prize came in just at the time we were saying that
                  we don&apos;t want to focus on the gloom and doom. We want to
                  focus on hope and solutions.”
                </Quote>
                <Spacer height={20} />
                <TextSmall>Pauline Herbommez</TextSmall>
                <TextSmall>Teacher at Eton College</TextSmall>
              </>
            )}
            {router.pathname !== '/ambassadors' && (
              <ScrollButton onClick={() => onScrollClick()}>Top</ScrollButton>
            )}
          </ImageStyled>
        </FlexBox>
      </FlexWrapperStyled>
    </FooterWrapper>
  );
};

interface IFooterWrapper {
  fullHeight?: boolean;
}

const FooterWrapper = styled.footer<IFooterWrapper>`
  ${({ fullHeight }) => css`
    min-height: ${fullHeight ? `100vh` : `100%`};

    > div {
      min-height: ${fullHeight ? `100vh` : `100%`};
    }
  `}
`;

const FlexWrapperStyled = styled(FlexWrapper)`
  height: 100%;
`;

const ImageStyled = styled(Image)`
  ${({ theme: { Consts } }) => css`
    align-items: flex-start;
    justify-content: flex-end;
    padding: 60px;

    @media (max-width: ${Consts.tabletBreakPoint}) {
      padding: 40px;
    }

    @media (max-width: ${Consts.mobileBreakPoint}) {
      padding: 20px;
    }
  `}
`;

const Breaker = styled.div`
  width: 100%;
  height: 1px;
  background: white;
`;

const H3 = styled.h3`
  ${({ theme: { Consts } }) => css`
    font-size: 16px;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    @media (min-width: ${Consts.mobileBreakPoint}) {
      font-size: 20px;
    }
    @media (min-width: ${Consts.tabletBreakPoint}) {
      font-size: 24px;
    }
  `}
`;

const Quote = styled.div`
  ${({ theme: { Consts } }) => css`
    font-size: 18px;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    @media (min-width: ${Consts.tabletBreakPoint}) {
      font-size: 30px;
    }
  `}
`;

const TopWrapper = styled.div`
  ${({ theme: { Consts } }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 30px;
    @media (max-width: ${Consts.mobileBreakPoint}) {
      margin-bottom: 15px;
    }
  `}
`;

const SectionsWrapper = styled.div`
  ${({ theme: { Consts } }) => css`
    width: 100%;
    margin-bottom: 30px;
    @media (max-width: ${Consts.mobileBreakPoint}) {
      margin-bottom: 15px;
    }
  `}
`;

const Button = styled.div`
  ${({ theme: { Consts } }) => css`
    width: 100px;
    height: 40px;
    border-radius: 25px;
    border-bottom-left-radius: 3px;
    background: white;
    color: black;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;

    @media (min-width: ${Consts.tabletBreakPoint}) {
      width: 120px;
      height: 70px;
      border-radius: 40px;
      border-bottom-left-radius: 6px;
      font-size: 14px;
    }
  `}
`;

const LegalWrapper = styled.div`
  ${({ theme: { Consts } }) => css`
    margin-bottom: 30px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @media (max-width: ${Consts.mobileBreakPoint}) {
      margin-bottom: 15px;
      flex-direction: column-reverse;
    }

    > div {
      &:nth-child(2) {
        max-width: 300px;
        @media (max-width: ${Consts.mobileBreakPoint}) {
          max-width: none;
          margin-bottom: 30px;
        }
        p {
          text-decoration: none;
          font-size: 16px;
          margin-bottom: 15px;
          text-align: right;
          cursor: auto;

          @media (max-width: ${Consts.mobileBreakPoint}) {
            text-align: left;
          }
        }

        div {
          p {
            font-size: 12px;
            text-align: right;
            margin-left: auto;
            margin-bottom: 0;

            @media (max-width: ${Consts.mobileBreakPoint}) {
              text-align: left;
              margin-left: inherit;
              margin-right: auto;
            }
          }
        }

        button {
          font-size: 12px;
          margin-left: auto;
          @media (max-width: ${Consts.mobileBreakPoint}) {
            margin-right: auto;
            margin-left: inherit;
            font-size: 16px;
          }
        }

        input {
          border-color: white !important;
          color: white;

          &::placeholder {
            color: white !important;
            font-weight: 200;
            opacity: 0.7;
          }
        }
      }
    }

    p {
      text-decoration: underline;
      cursor: pointer;
    }
  `}
`;

const BottomWrapper = styled.div`
  ${({ theme: { Consts } }) => css`
    display: flex;
    align-items: center;

    p,
    a {
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        margin-left: 25px;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    @media (max-width: ${Consts.mobileBreakPoint}) {
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 1fr;
      grid-gap: 5px 20px;
      margin-top: 10px;

      p {
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          margin-left: 0;
        }
      }
    }
  `}
`;

const ScrollButton = styled.div`
  ${({ theme: { Consts } }) => css`
    width: 100px;
    height: 40px;
    border-radius: 25px;
    border-bottom-left-radius: 3px;
    background: black;
    color: white;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-weight: bold;
    flex-shrink: 0;
    margin-top: 150px;
    cursor: pointer;
    @media (min-width: ${Consts.tabletBreakPoint}) {
      width: 140px;
      height: 80px;
      border-radius: 40px;
      border-bottom-left-radius: 6px;
      font-size: 16px;
      bottom: 60px;
      left: 60px;
    }
  `}
`;

const ALink = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 5px 0px;
  cursor: pointer;
`;

const MiniButton = styled.div`
  width: 30px;
  height: 20px;
  border-radius: 10px;
  border-bottom-left-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  color: black;
  flex-shrink: 0;
  svg {
    width: 15px;
    height: 15px;
  }
`;
