import styled, { css } from 'styled-components';

interface IFlexWrapper {
  isReversedDirection?: boolean;
  isfullPage?: boolean;
  mobileHalfPage?: boolean;
  noMaxHeight?: boolean;
}

interface IFlexBox {
  background?: string;
  color?: string;
  padding?: string;
  justify?: string;
  direction?: string;
  withoutPadding?: boolean;
  align?: string;
}

export const FlexWrapper = styled.div<IFlexWrapper>`
  ${({ isfullPage, mobileHalfPage, noMaxHeight, theme: { Consts } }) => css`
    width: 100%;
    min-height: ${noMaxHeight ? '0px' : '100vh'};
    display: grid;
    grid-template-columns: ${isfullPage ? '1fr' : '1fr 1fr'};

    @media (max-width: ${Consts.smallTabletBreakPoint}) {
      grid-template-rows: ${isfullPage
        ? '1fr'
        : 'minmax(50vh, auto) minmax(50vh, auto)'};
      grid-template-columns: initial;
      min-height: ${mobileHalfPage
        ? '50vh'
        : `${noMaxHeight ? '0px' : '100vh'}`};
      height: ${mobileHalfPage ? '50vh' : 'auto'};
    }
  `}
`;

export const FlexBox = styled.div<IFlexBox>`
  ${({
    background,
    color,
    justify = `center`,
    withoutPadding,
    align,
    direction,
    padding,
    theme: { Consts },
  }) => css`
    display: flex;
    background: ${background};
    color: ${color};
    flex-direction: ${direction || 'column'};
    justify-content: ${justify};
    flex-grow: 1;
    padding: ${withoutPadding ? '0' : padding || '60px'};
    position: relative;
    align-items: ${align};
    @media (max-width: ${Consts.tabletBreakPoint}) {
      padding: ${!withoutPadding && `40px`};
    }

    @media (max-width: ${Consts.mobileBreakPoint}) {
      padding: ${withoutPadding ? '0' : padding || '25px 20px'};
    }
  `}
`;
