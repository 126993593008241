import styled, { css } from 'styled-components';

interface IImage {
  image: string;
  position?: string;
  size?: string;
}

export const Image = styled.div<IImage>`
  ${({ image, position = 'center', size = 'cover', theme: { Consts } }) => css`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    background-image: url(${image});
    background-size: ${size};
    background-position: ${position};
    background-repeat: no-repeat;
    flex-direction: column;
    padding: 30px 60px;
    overflow: hidden;
    position: relative;

    @media (max-width: ${Consts.tabletBreakPoint}) {
      padding: 30px 60px;
    }
  `}
`;
