import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { ColorDefs } from 'styles/colorDefs';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Input } from '../Inputs/Input';
import { GoogleAutocomplete } from '../Inputs/GoogleAutocomplete';

interface IFormInputWrapper {
  value?: string;
  type?: string;
  placeholder?: string;
  onChange?: (e) => void;
  onPaste?: (e) => void;
  onBlur?: (e) => void;
  selected?: string;
  dateFormat?: string;
  placeholderText?: string;
  minDate?: Date;
  maxDate?: Date;
  color?: string;
  text?: string;
  isEmpty?: boolean;
  messageTitle?: string;
  error?: string;
  messageText?: string;
  messageButton?: string;
  googleAutocomplete?: boolean;
}

export const FormInputWrapper: FunctionComponent<IFormInputWrapper> = ({
  value,
  type,
  placeholder,
  onChange,
  onBlur,
  selected,
  dateFormat,
  placeholderText,
  minDate,
  maxDate,
  color,
  isEmpty = false,
  text,
  messageTitle,
  messageText,
  googleAutocomplete,
  error,
  messageButton,
  onPaste,
}) => {
  return (
    <FormInputWrapperWrap>
      {!isEmpty ? (
        <>
          {dateFormat && (
            <FormDate>
              <DatePicker
                selected={selected}
                dateFormat={dateFormat}
                onChange={onChange}
                onBlur={onBlur}
                placeholderText={placeholderText}
                minDate={minDate}
                maxDate={maxDate}
              />
              {error && <Error>{error}</Error>}
            </FormDate>
          )}
          {googleAutocomplete && (
            <FormInput color={color}>
              <GoogleAutocomplete
                value={value}
                type={type}
                onChange={onChange}
                onBlur={onBlur}
                placeholder={placeholder}
                color={color}
                messageTitle={messageTitle}
                messageText={messageText}
                messageButton={messageButton}
                autoComplete="off"
              />
              {error && <Error>{error}</Error>}
            </FormInput>
          )}
          {!dateFormat && !googleAutocomplete && (
            <FormInput color={color}>
              <Input
                value={value}
                type={type}
                onChange={onChange}
                onBlur={onBlur}
                placeholder={placeholder}
                color={color}
                messageTitle={messageTitle}
                onPaste={onPaste}
                messageText={messageText}
                messageButton={messageButton}
                autoComplete="new-password"
              />
              {error && <Error>{error}</Error>}
            </FormInput>
          )}
        </>
      ) : (
        <>{text.length > 0 && <RadioInfo>{text}</RadioInfo>}</>
      )}
    </FormInputWrapperWrap>
  );
};

const RadioInfo = styled.p`
  font-size: 12px;
  margin: 0 0 0 10px;
  padding-bottom: 17px;
  color: ${ColorDefs.robinsEggBlue};
  height: 100%;
  display: flex;
  align-items: center;
`;

const Error = styled.p`
  font-size: 12px;
  margin: 0 0 0 10px;
  padding-bottom: 17px;
  color: red;
  height: 100%;
  display: flex;
  align-items: center;
`;

const FormInputWrapperWrap = styled.div`
  width: 100%;
  position: relative;
`;

const FormInput = styled.div<{ color?: string }>`
  ${({ color }) => css`
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    input {
      border: 1px solid ${ColorDefs.robinsEggBlue};
      font-size: 14px;
      color: ${color || ColorDefs.robinsEggBlue};

      &::placeholder {
        color: ${ColorDefs.robinsEggBlue};
        opacity: 0.7;
      }
    }

    button {
      color: ${ColorDefs.robinsEggBlue};
      border-color: ${ColorDefs.robinsEggBlue};
    }
  `}
`;

const FormDate = styled(FormInput)`
  .react-datepicker-wrapper {
    line-height: 0;
    width: 100%;
    margin-bottom: 17px;

    input {
      background-color: transparent;
      color: ${ColorDefs.robinsEggBlue};
      border: 1px solid ${ColorDefs.robinsEggBlue};
      height: 48px;
      padding: 0 10px;
      width: 100%;

      &::placeholder {
        color: ${ColorDefs.robinsEggBlue};
        opacity: 0.7;
      }
    }
  }

  .react-datepicker__tab-loop {
    z-index: 3;

    .react-datepicker-popper {
      margin-top: 10px;

      .react-datepicker {
        border: 0;

        .react-datepicker__triangle {
          &::before,
          &::after {
            border-bottom-color: ${ColorDefs.robinsEggBlue};
          }
        }

        .react-datepicker__header {
          background-color: ${ColorDefs.robinsEggBlue};
        }

        .react-datepicker__month {
          background-color: ${ColorDefs.neutralBlack};
          margin: 0;
        }

        .react-datepicker__day-name,
        .react-datepicker__day,
        .react-datepicker__time-name {
          width: 1.2rem;
          line-height: 1.2rem;
          font-size: 0.65rem;
        }

        .react-datepicker__current-month {
          font-size: 0.7rem;
        }

        .react-datepicker__day {
          color: ${ColorDefs.neutralWhite};

          &:hover {
            background-color: ${ColorDefs.robinsEggBlue};
            color: ${ColorDefs.neutralBlack};
          }
        }

        .react-datepicker__day--disabled {
          color: #ddd;
          opacity: 0.5;

          &:hover {
            background-color: ${ColorDefs.neutralBlack};
            color: #ddd;
          }
        }

        .react-datepicker__day--selected,
        .react-datepicker__day--keyboard-selected {
          background-color: ${ColorDefs.robinsEggBlue};
        }

        .react-datepicker__navigation-icon {
          &::before {
            border-color: black;
          }
        }

        .react-datepicker__navigation-icon--next,
        .react-datepicker__navigation-icon--previous {
          width: 0;
        }
      }
    }
  }
`;
